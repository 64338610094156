<template>
  <div class="container">
    <div class="content">
      <input
        type="file"
        multiple="multiple"
        name="mfiles"
        class="hidden"
        accept=".bin"
        @change="handleInputChange"
      />
      <div class="area">
        <div>
          <div>文件上传</div>
          <p>1、 点击“上传”按钮，上传文件。</p>
          <p>2、一次最多支持上传4个文件，文件上传完成后，点击“提交”按钮。</p>
          <p>3、 提交到服务器后，将会把对应的文件链接展示在下面的文本框。</p>
          <p>4、 复制文本框中的链接（多个链接是以 ; 隔开），写入对应测试设备。</p>
          <button
            type="primary"
            @click="handleButtonClick"            
            :disabled="state.fileList.length >= 4"
          >
            上传
          </button>
          <button
            style="margin-left: 20px"
            type="primary"
            @click="handleSubmitClick"
            :disabled="state.fileList.length > 4 || state.fileList.length == 0"
          >
            提交
          </button>
          <ul class="upload-list">
            <li
              class="upload-list__item is-success"
              v-for="(file, index) in state.fileList"
              :key="index"
            >
              <a class="upload-list__item-name">
                <i class="icon fa fa-file-zip-o"></i>
                {{ file.name }}
              </a>
              <label class="upload-list__item-status-label">
                <i class="icon fa fa-close" @click="handleRemove(index)"></i>
              </label>
            </li>
          </ul>
        </div>
      </div>

      <textarea
        placeholder="请上传文件"
        name="name"
        rows="10"
        cols="80"
        v-model="state.imgSrc"
        disabled
      ></textarea>    
    </div>
  </div>
</template>

<script>
import { nextTick, reactive } from "vue";
import axios from "axios";
export default {
  name: "UploadFile",
  setup() {
    const state = reactive({
      saveLoading: false,
      imgSrc: "",
      input: "",
      fileList: [],
    });
    const handleInputChange = (e) => {
      if (e.target.files[0]) {
        state.fileList.push(e.target.files[0]);
      }
    };    
    const handleButtonClick = () => {
      state.input.click();
    };
    const handleAreaDragover = (e) => {
      e.preventDefault();
    };
    const handleRemove = (index) => {
      state.fileList.splice(index, 1);
    };
    const handleSubmitClick = () => {
      let param = new FormData();
      let files = Array.from(state.fileList);
      files.forEach((file)=>{
        param.append("file",file)
      })     
      let config = {
        headers: {
          "Content-Type": "multipart/form-data"          
        },       
      };
      let url = 'https://fskupload.abupdate.com/fskapi/file/upload'
      axios.post(url,param,config).then(res=>{
        if(res.data.code==200){
          state.imgSrc = res.data.data.fileUrls.join('; ')
        }
      }).catch(err=>{
        console.log(err)
      })
    };
    nextTick(() => {
      state.input = document.querySelector("input");
    });

    return {
      state,
      handleInputChange,     
      handleButtonClick,
      handleAreaDragover,
      handleRemove,
      handleSubmitClick,
    };
  },
};
</script>

<style>
.hidden {
  display: none;
}
.area {
  width: 100%;
  height: 380px;
  border: 1px dashed #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 4px;
}
.content {
  width: 50%;
  margin: 30px auto;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.2);
  padding: 20px;
  border-radius: 4px;
}
textarea {
  width: 100%;
  border: 1px solid #dbdbdb;
  padding: 10px;
  font-size: 16px;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 20px;
}
p {
  margin: 20px 0;
}
.backBtn {
  margin-top: 20px;
  text-align: right;
}
button {
  background-color: #00d1b2;
  color: #fff;
  border-color: transparent;
  border-radius: 4px;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  cursor: pointer;
  outline: none;
}
button[disabled] {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #ffffff;
  border-color: #ebeef5;
}
.upload-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.upload-list__item:first-child {
  margin-top: 10px;
}
.upload-list__item {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
  font-size: 14px;
  color: #606266;
  line-height: 1.8;
  margin-top: 5px;
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
}
.upload-list__item-name {
  color: #606266;
  display: block;
  margin-right: 40px;
  overflow: hidden;
  padding-left: 4px;
  text-overflow: ellipsis;
  transition: color 0.3s;
  white-space: nowrap;
}
.upload-list__item-name [class^="icon"] {
  height: 100%;
  margin-right: 7px;
  color: #909399;
  line-height: inherit;
}
.upload-list__item-status-label {
  position: absolute;
  right: 5px;
  top: 0;
  line-height: inherit;
  /* display: none; */
}
</style>
